<template>
    <va-modal
       v-model="s_data"
       @cancel="onCancel"
       hideDefaultActions
       class="flex xs12"
       title="Available Messages"
       size="large"
       ok-text="Ok"
    >

    <div class="flex xs12">
        <div>
            <!-- Desktop Version -->
            <div class="custom-container" >
            <div>
                <div class="custom-header">
                <div>NIHSS Support <span class="custom-text-gray">#{{ ticketData.id }}</span></div>
                <span class="custom-response-time">
                  This is not a Real Time Chat
                </span>
                </div>
            </div>
            <!-- Notification Table -->
            <div class="custom-table-container">
                <div class="custom-chat-bubbles" ref="chatContainer" v-if="ticketData.adminID && messages.length > 0">
                    <div v-for="(item, index) in messages" :key="index" :isAdmin="ticketData.userID != item.senderUID" class="custom-message-container">
                        <div class="custom-message">
                         {{ item.message }}
                        <p class="custom-message-time">{{ formatDate(item.createdAt) }}</p>
                        </div>
                    </div>
                </div>
                <div class="custom-info-bar" v-else-if="loading">
                    <span class="custom-info-text pt-2">Loading...</span>
                </div>
                <div class="custom-info-bar" v-else>
                    <span class="custom-info-text pt-2">No messages found</span>
                </div>
            </div>
            <!-- Message Input and Send Button -->
            <div class="custom-input-container">
                <div class="custom-textarea-container">
                <textarea
                    @keydown.enter.prevent="sendMessageOnEnter"
                    v-model="message"
                    class="custom-textarea"
                    placeholder="Write your message..."
                ></textarea>
                <!-- Button positioned in the bottom right corner of the textarea -->
                <Button v-if="!timer_enabled" ref="sendbtn" @click="replyTicket()" :disabled="!canSendMessage" class="custom-send-button">
                    {{  this.loading ? 'Sending...' : 'Send Message'}}
                </Button>
                <Button v-else :disabled="true" class="custom-send-button">
                    Wait {{  this.timer }} Seconds
                </Button>
                </div>
            </div>
            </div>
        </div>
    </div>
   </va-modal>
</template>
<script>
import axios from '../../../scripts/interceptor.js'
import SpamController from '../../../class/SpamController.js'
export default {
   name: 'Notes',
   props: {
       data: {
           required: true,
       },
       messages: {
           default: 5,
           required: true,
       },
       ticketData: {
           required: true,
       },
   },
   data() {
       return {
           s_data: false,
           message: '',
           loading: true,
           //spam settings
            message_count: 0,
            message_limit: 3,
            coldowntimer: 45,
            timer: 0,
            timer_enabled: false,
       }
   },
   
   computed: {
        canSendMessage() {
            return this.message.length > 0 && !this.loading;
        },
       NoteFields(){
           return [{
               name: 'adminID',
               title: 'AdminID',
               sortField: 'adminID',
               width: '30%',
           },
           {
               name: 'note',
               title: 'Note',
               sortField: 'note',
               width: '50%',
           }, 
           {
               name: 'date',
               title: 'Created At',
               sortField: 'date',
               width: '20%',
           }, 
           {
               name: '__slot:actions',
               title: 'Actions',
               sortField: 'actions',
               width: '10%',
           }, 
           ];
       },
   },
   methods: {
       onCancel() {
           this.$emit('cancel');
       },
       scrollToBottom() {
            const container = this.$refs.chatContainer;
            if(container)
              container.scrollTop = container.scrollHeight;
        },
        /**
         * @description Checks if user already sent the message limit in the time limit
         * @returns {boolean}
         */
        async spam_validator(message, title) {
            return await new Promise((resolve, reject) => {
                if (this.message_count >= this.message_limit) {
                    reject({
                        message: "You are sending too many messages, please wait a few seconds.",
                        title: "Spam Alert",
                        status: false
                    });
                    this.message_count = 0;
                    if( this.message_limit - 1 > 0){
                        this.message_limit -= 1;
                    }
                }
                resolve({
                    message: message,
                    title: title,
                    status: true
                });
            })
        },
       activeSpamTimer(){
           this.timer_enabled = true;
           this.timer = this.coldowntimer
            //update the timer in real time
            let interval = setInterval(() => {
                this.timer = this.timer - 1;
                if(this.timer <= 0){
                    this.timer_enabled = false;
                    this.timer = 0;
                    this.message_count = 0;
                    this.coldowntimer += 5;
                    clearInterval(interval);
                }
            }, 1000);
       },
       async replyTicket(){
        if(!this.canSendMessage){
            return;
        }
        try {
          await this.spam_validator(this.message, 'message')
        } catch (error) {
          this.activeSpamTimer();
          this.$swal.fire({
              title: error.title,
              text: error.message,
              icon: 'error',
              confirmButtonText: 'Ok'
          });
          return;
        }
        if(this.timer_enabled){
            return;
        }
        this.loading = true;
        //check if message and ticketData are not empty
        if(!this.message || !this.ticketData.id){
            this.$swal.fire({
                title: 'Error!',
                text: 'Message or ticket data is empty',
                icon: 'error',
                confirmButtonText: 'Ok'
            });
        }
        //Create a new message in the database
        axios.post(`api/admin/replyTicket`,{
            ticketID: this.ticketData.id,
            mailto: this.ticketData.email,
            message: this.message,
        }).then((result)=>{
            //Add it to the box
            this.$emit('newMessage', {
                message: this.message,
                data: this.ticketData
            });
            this.message_count++;
            this.loading = false;  
            this.message = '';
            // Scroll to the bottom
            this.$nextTick(() => {
              this.scrollToBottom();
            });
        })
        .catch((error) => {
            this.$swal.fire({
                title: 'Error!',
                text: error.response.data.errormsg || error.response.data.message,
                icon: 'error',
                confirmButtonText: 'OK'
            });
            this.loading = false;  
            this.message = '';
        });
       },
       sendMessageOnEnter(event) {
            if(this.canSendMessage){
                if (event.key === 'Enter' && !event.shiftKey) {
                    this.replyTicket();
                }
            }
        },
       formatDate(date) {
        //date will have seconds, so we need to multiply by 1000
        let dateSecondsTimestamp = date._seconds || date.seconds;
        return new Date(dateSecondsTimestamp * 1000).toLocaleString();
       },
   },
   watch: {
       data: {
           handler() {
               this.s_data = this.data;
           },
           immediate: true,
       },
       messages: {
           handler() {
             this.loading = false
             this.$nextTick(() => {
              this.scrollToBottom();
            });
           },
           immediate: true,
       },
   },
}
</script>
<style scoped>
.custom-container {
  width: 100%;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.1);
  background-color: #f7fafc;
}

.custom-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #ffffff;
  padding: 1rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.custom-info-bar {
  background-color: #f7fafc;
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-info-text {
  text-align: center;
  padding-top: 2px;
  color: #4a5568;
}

.custom-text-gray {
  color: #718096;
}

.custom-response-time {
  margin-left: 2px;
  font-weight: 300;
  text-transform: capitalize;
  color: #a0aec0;
}

.custom-table-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 500px;
}

.custom-chat-bubbles {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
  width: 100%;
  overflow-y: auto;
  margin-top: 1rem;
}

.custom-message-container {
  display: flex;
  width: 100%;
  justify-content: flex-start;
}

.custom-message-container[isAdmin="true"] {
  justify-content: flex-end;
}

.custom-message-container[isAdmin="true"] .custom-message {
  background-color: #2c3e5e;
}

.custom-message {
  background-color: #393c3f;
  color: #ffffff;
  border-radius: 0.375rem;
  padding: 0.5rem;
  margin-left: 1rem;
  margin-bottom: 1rem;
  margin-right: 1rem;
}

.custom-message-time {
  font-size: 0.75rem;
  opacity: 0.5;
}

.custom-input-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  background-color: #ffffff;
  width: 100%;
  position: relative;
}

.custom-textarea-container {
  width: 100%;
  display: flex;
  position: relative;
}

.custom-textarea {
  width: 100%;
  height: 52px;
  padding: 0.5rem;
  border-top: 1px solid #e2e8f0;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  outline: none;
  resize: none;
}

.custom-send-button {
  text-align: center;
  background-color: #4a5568;
  color: #ffffff;
  border: none;
  padding: 1rem 1rem;
  cursor: pointer;
}

.custom-send-button:disabled {
  background-color: #cbd5e0;
  color: #a0aec0;
  cursor: not-allowed !important;
}

.custom-send-button:hover {
  cursor: pointer;
  background-color: #718096;
}

</style>